import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : props.theme.sizes.maxWidth};
  padding: ${props => props.padding || '2em 1.5em 2em'};
  flex-grow: 1;
`

const Container = props => {
  return <Wrapper padding={props.padding} maxWidth={props.maxWidth}>{props.children}</Wrapper>
}

export default Container
